html {
  font-size: 16px;
}

html, body, #app {
  background: #f8f8f8;
  height: 100%;
}

#app {
  flex-flow: column;
  display: flex;
}

.main-container {
  flex: auto;
  overflow: auto;
}

/*# sourceMappingURL=index.77116e68.css.map */
