html {
	font-size: 16px;	
}

html, body, #app {
	background: #f8f8f8;
	height: 100%;
}

#app {
	display: flex;
	flex-flow: column;
}

.main-container {
	flex: 1 1 auto;
	overflow: auto;
}

